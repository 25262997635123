@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;900&display=swap');

* {
  font-family: 'Montserrat', sans-serif;
}





/* p, a, h1, h2, h3, h4, h5, h6, h7, li {
  @apply leading-5 md:leading-8
} */
.def-transition  {
  @apply transition-all ease-in-out duration-500
}
.basic-separation {
  margin-top: 9vw;
  margin-bottom: 3vw;
}
.basic-separation-top {
  margin-top: 9vw;
}
.basic-separation-bottom {
  margin-bottom: 3vw;
}

/* scrollbar */
*::-webkit-scrollbar {
  @apply w-2 h-2
}
*::-webkit-scrollbar-track {
  @apply bg-gray-300 border border-yellow-300
}
*::-webkit-scrollbar-thumb {
  @apply def-transition bg-yellow-300 hover:bg-yellow-500
}



/* Base Fontsize */
h1 {
  font-weight: 400;
  @apply text-5xl
}
h2 { 
  font-weight: 400;
  @apply text-4xl 
}
h3 { 
  font-weight: 400;
  @apply text-3xl 
}
h4 { 
  font-weight: 400;
  @apply text-2xl 
}
h5 { 
  font-weight: 400;
  @apply text-xl 
}
h6 { 
  font-weight: 400;
  @apply text-lg 
}
h7 { 
  font-weight: 400;
  @apply text-base 
}
p, a, label, button, input, select, ul, ol {
  font-weight: 400;
  @apply text-tiny
}
hr {
  @apply border-t-2 border-black rounded-full
}
ul li {
  @apply list-disc ml-7 mb-5
}
a {
  @apply def-transition text-blue-500 hover:underline visited:text-indigo-500
}
.a-no-styles {
  @apply def-transition text-black no-underline hover:no-underline visited:text-black
}


/* New classes */
.responsive-subtitle {
  @apply font-semibold text-2xl md:text-4xl xl:text-5xl
}
.responsive-subtitle-small {
  @apply font-semibold text-lg md:text-xl xl:text-2xl
}
.responsive-text {
  @apply text-xs md:text-base xl:text-lg leading-6 md:leading-7 xl:leading-8
}
.responsive-text-small {
  @apply text-xs md:text-xs xl:text-sm
}

.video-box {
  @apply def-transition bg-white hover:cursor-pointer hover:scale-[102%] shadow-xl md:shadow-none hover:shadow-xl 
}
.modal-all {
  @apply fixed w-screen h-screen top-0 left-0 bg-gray-900 bg-opacity-80 z-50 flex items-center justify-center pl-2 pr-6 overflow-hidden
}
.modal-content {
  @apply w-full sm:w-8/12 bg-white shadow-2xl m-3 overflow-hidden
}
.modal-video-info {
  max-width: 100vw;
  max-height: 90vh;
  @apply w-full h-full overflow-y-auto relative
}
.modal-button {
  @apply def-transition bg-gray-500 bg-opacity-75 hover:bg-gray-50 hover:bg-opacity-80 p-1 my-28 z-50 disabled:pointer-events-none disabled:opacity-20
}



/* Header video styles */
.content {
  margin-top: -5px;
  padding-inline: 7vw;
  padding-top: 0.5vw;
  padding-bottom: 5vw;
  z-index: 2 !important;
  background-color: white;
  position: relative;
}

.video-title {
  z-index: 10;
  @apply fixed top-0 font-bold text-yellow-300 text-3xl md:text-5xl xl:text-6xl pt-[5vw]
}
.video-title-shadow {
  z-index: 10;
  @apply fixed top-0 font-bold text-black text-3xl md:text-5xl xl:text-6xl ml-[2px] md:ml-[3px] xl:ml-[6px] pt-[5vw]
}
.video-subtitle {
  z-index: 10;
  @apply fixed top-10 md:top-16 xl:top-20 text-yellow-300 text-sm md:text-lg xl:text-xl pt-[5vw]
}
.video-subtitle-shadow {
  z-index: 10;
  @apply fixed top-10 md:top-16 xl:top-20 text-black text-sm md:text-lg xl:text-xl ml-[1px] pt-[5vw]
}
.bg-video {
  z-index:-1;
  @apply h-screen w-full object-cover
}
.video-container {
  overflow: hidden;
  max-width: 100vw;
  border: 0 !important;
  @apply relative bg-black flex items-center min-h-[200px] md:min-h-[250px] max-h-[20vh] md:max-h-[50vh] lg:max-h-[65vh]
}
.video-container-transition {
  max-width: 100vw;
  overflow: hidden;
  border: 0 !important;
  @apply absolute w-full h-full flex flex-col items-start justify-start min-h-[200px] md:min-h-[250px] max-h-[20vh] md:max-h-[50vh] lg:max-h-[65vh]
}
.video-page-transition {
  height: 11vw;
  bottom: -4.5vw;
  z-index: 5;
  @apply scale-110 absolute w-screen bg-white -rotate-6
}
.video-page-content {
  border: 0 !important;
  z-index: 3;
  @apply relative
}






